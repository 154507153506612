<template>
	<div class="footCls">
		<div class="footMain" v-if="webFooterInfo" v-html="webFooterInfo.word">
			<!-- <div>
        <ul>
          <li>登录注册
            <i class="el-icon-caret-right"></i>
          <li>选择课程
            <i class="el-icon-caret-right"></i>
          <li>支付费用
            <i class="el-icon-caret-right"></i>
          <li>在线学习
            <i class="el-icon-caret-right"></i>
          <li>在线考试
            <i class="el-icon-caret-right"></i>
          <li>获得证书
            <i class="el-icon-caret-right"></i>
          </li>
        </ul>
      </div>
      <p style="margin-bottom:20px"><span style="margin-right:30px">站点名称：{{webSiteObj.name}}</span></p>
      <p style="margin-bottom:20px"><span style="margin-right:30px">网址：{{webSiteObj.url}}</span><span>备案信息：{{webSiteObj.archival}}</span></p>
      <p style="margin-bottom:20px"><span style="margin-right:30px">主办单位：{{webSiteObj.hostUnit}}</span><span>技术支持：{{webSiteObj.technicalSupport}}</span></p>
      <p style="margin-bottom:20px"><span style="margin-right:30px">客服工作时间：{{webSiteObj.serverTime}}</span><span>客服电话：{{webSiteObj.serverPhone}}</span></p>
      <p style="margin-bottom:20px"><span style="margin-right:30px">客服邮箱：{{webSiteObj.serverEmail}}</span><span>举报电话：{{webSiteObj.reportPhone}}</span></p>
      <p style="margin-bottom:20px"><span style="margin-right:30px">举报邮箱：{{webSiteObj.reportEmail}}</span><span>地址：{{webSiteObj.companyAddress}}</span></p>
      <p>备案号：{{webSiteObj.internetContentProvider}}</p> -->
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {}
		},
		computed: {
			...mapState(['webSiteObj', 'webFooterInfo'])
		}

	}
</script>

<style lang="less" scoped>
	.main {
		width: 1200px;
		margin: 0 auto;
	}

	.footCls {
		height: 100%;
		background: #041366;
		color: #fff;
		font-size: 16px;
		text-align: center;
		font-family: PingFang SC;
		padding: 50px 0;

		.footMain {
			// width: 924px;
			margin: 0 auto;

			ul {
				display: flex;
				justify-content: space-between;
				padding-top: 24px;
				margin-bottom: 48px;

				li {
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: bold;
				}
			}
		}
	}
</style>
